<template>
	<div id="miniGoodsList">
		<page-goods-list :is-shop="1"></page-goods-list>
	</div>
</template>

<script>
	import pageGoodsList from '@/components/layout/goods/page-goods-list.vue'
	export default{	
		name:'goods-list',
		components:{
			pageGoodsList
		},
		beforeRouteLeave (to, from, next) { 
		    from.meta.keepAlive = false;
		    next();
		},
	}
</script>

<style>
</style>
